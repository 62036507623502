import { useAppContext, withApp } from "contexts";
import MyBetsTabNav from "./components/MyBetsTabNav";
import { useEffect, useState } from "react";
import { HouseGameType } from "helpers/constants";
import { DualRingInner, DualRingLoader, Flex, Pages } from "components/common";
import MyBetsTabTable from "./components/MyBetsTabTable";
import { Root } from "./styles";
import { css } from "styled-components";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { ExceptionHandlingType } from "types/apiServiceTypes";

export type GameBet = {
  id: number;
  amount: number;
  gameId: number;
  gameType: HouseGameType;
  payout: number;
  settledAt: string;
};

export type MyBetsTabProps = { userId?: string };

const take: number = 5;

const MyBetsTab: React.FC<MyBetsTabProps> = ({ userId }) => {
  const { apiService, profile } = useAppContext();

  const [skip, setSkip] = useState<number>(0);
  const [betsType, setBetsType] = useState<HouseGameType | undefined>();

  const isOwnProfile = userId === profile?.id;

  const { data, isFetching } = useQuery<{ data: GameBet[]; total: number }>({
    queryKey: ["user-bets", { userId, skip, betsType }],
    queryFn: async () => {
      const data = await apiService.get<{ data: GameBet[]; total: number }>(
        `/bets${isOwnProfile ? "/my?" : `?userId=${userId}&`}take=${take}&skip=${skip}${
          betsType ? `&gameType=${betsType}` : ""
        }`,
        { exceptionHandlingType: ExceptionHandlingType.AUTOMATIC }
      );

      return data;
    },
    enabled: !!userId || !profile?.id,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    setSkip(0);
  }, [betsType]);

  const onPage = (index: number) => {
    setSkip(index * take);
  };

  return (
    <Root style={{ flexGrow: 1 }}>
      <MyBetsTabNav betsType={betsType} setBetsType={setBetsType} />
      {isFetching ? (
        <Flex align="center" justify="center" padding="120px 0px">
          <DualRingLoader>
            <DualRingInner />
          </DualRingLoader>
        </Flex>
      ) : (
        <div
          style={{
            width: "100%",
          }}
        >
          <MyBetsTabTable bets={data?.data ?? []} />
        </div>
      )}
      <Pages
        showInfo={false}
        skip={skip}
        take={take}
        total={data?.total || 0}
        onClick={(index) => {
          onPage(index);
        }}
      />
    </Root>
  );
};

export default MyBetsTab;
