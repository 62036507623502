import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { useAppContext } from "contexts";
import { AdminActionMFA } from "types/admin-management";
import { ExceptionHandlingType } from "types/apiServiceTypes";
import { User } from "types/user";
import { UserConnectionChangeRequest } from "types/user/user-constrained";
interface GamdomUnlinkProps {
  userId: string;
}

export default function useUnlinkGamdom(
  options?: UseMutationOptions<User, Error, GamdomUnlinkProps>
) {
  const { apiService } = useAppContext();
  return useMutation({
    mutationKey: ["admin/user-constrained/unlink/gamdom"],
    mutationFn: async ({ userId }: GamdomUnlinkProps) => {
      const response = await apiService.request(
        `/admin/user-constrained/unlink/gamdom/${userId}`,
        {
          method: "PUT",
        },
        ExceptionHandlingType.AUTOMATIC
      );

      const data: User = await response?.json();
      return data;
    },

    ...options,
  });
}
