export enum ExceptionHandlingType {
  SILENT = "0",
  AUTOMATIC = "1",
  MANUAL = "2",
}

export interface ApiException {
  code: string;
  message: string;
  statusCode: number;
}

export interface ApiServiceRequestProps {
  init?: RequestInit | undefined;
  exceptionHandlingType?: ExceptionHandlingType;
}

export interface ApiServicePostProps {
  init?: Omit<RequestInit, "body"> | undefined;
  exceptionHandlingType?: ExceptionHandlingType;
}

export const DefaultRequest: ApiServiceRequestProps = {
  exceptionHandlingType: ExceptionHandlingType.MANUAL,
};
