import React from "react";
import { Pages } from "components/common";
import styled, { css } from "styled-components";
import { IAppContext, withApp } from "contexts";
import { ProfilePointsShopTable } from "./ProfilePointsShopTable";
import { IUserDto } from "interfaces";
import { PointsShopHeader } from "./PointsShopHeader";
import { scrollToElement } from "helpers/scrollToElement";
import { PointsShopItem } from "types/points-shop";

export interface PointsShopTabProps {
  user: IUserDto | null;
}

export interface PointsTransaction {
  id: number;
  userId: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  discordId: string | null;
  pointsShopItemId: number;
  rejectionReason: string | null;
  user: IUserDto;
  pointsShopItem: PointsShopItem;
}

const RafflesTab: React.FC<PointsShopTabProps & IAppContext> = ({
  accessFetch,
  user,
}): React.ReactElement => {
  const [value, setValue] = React.useState<string>("");
  const [items, setItems] = React.useState<any>([]);

  const [skip, setSkip] = React.useState<number>(0);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [sorter, setSorter] = React.useState<string>("createdAt:asc");
  const [take, setTake] = React.useState<number>(6);
  const [total, setTotal] = React.useState<number>(0);

  const fetchData = React.useCallback(async () => {
    if (user) {
      try {
        setIsLoading(true);
        const searchParam = value ? `search=${value}` : "";
        const sortParam = `sort=${sorter}`;
        const skipTakeUserParam = `skip=${skip}&take=${take}&userId=${user.id}`;

        const response = await accessFetch(
          `/point-shop/purchases?${searchParam}${
            searchParam && sortParam ? "&" : ""
          }${sortParam}&${skipTakeUserParam}`
        );

        const data = await response?.json();
        setItems(data?.items);
        setTotal(data?.total); // TO DO
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    }
  }, [accessFetch, value, skip, sorter, take, user]);

  React.useEffect(() => {
    fetchData(); // Initial data fetch
  }, [fetchData]);

  const onPage = (index: number) => {
    setSkip(index * take);
    setTake(take);
  };

  const profilePointsShopTableContainerId = "profilePointsShopTableContainerId";

  return (
    <Container>
      <TableContainer id={profilePointsShopTableContainerId}>
        <PointsShopHeader
          boxStyles={css`
            margin: 15px auto;
          `}
          setSorter={setSorter}
          sorter={sorter}
          skip={skip}
          setSkip={setSkip}
          setValue={setValue}
        />
        <ProfilePointsShopTable
          $keys={["id", "name", "price", "status", "createdAt"]}
          items={items ?? []}
          skip={skip}
          isLoading={isLoading}
          take={take}
        />
        <div style={{ marginTop: 20 }}>
          <Pages
            onClick={(index) => {
              onPage(index);
              scrollToElement(profilePointsShopTableContainerId);
            }}
            skip={skip}
            take={take}
            total={total}
            showInfo={false}
          />
        </div>
      </TableContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 1920px;
  overflow: hidden;
  width: 100%;
  //   @media (max-width: 1280px) {
  //     width: 90%;
  //   }
`;

const TableContainer = styled.div`
  position: relative;
  margin: auto;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
`;
export default withApp(RafflesTab);
