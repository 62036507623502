import { UseQueryOptions, keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAppContext } from "contexts";
import { PaginatedItems } from "types/api";
import { ExceptionHandlingType } from "types/apiServiceTypes";
import { CoinBoxHistoryItem } from "types/treasury";

export function useCoinBoxHistory(
  params: { userId?: string; take: number; skip: number } = { take: 10, skip: 0 },
  options?: UseQueryOptions<PaginatedItems<CoinBoxHistoryItem[]>, Error>
) {
  const { apiService } = useAppContext();
  return useQuery({
    queryKey: ["coin-boxes-history", params],
    queryFn: async () => {
      let url = "";
      if (params.userId) {
        url = `/admin/coin-box/user-history/${params.userId}?take=${params.take}&skip=${params.skip}`;
      } else {
        url = `/coin-box/history?take=${params.take}&skip=${params.skip}`;
      }
      const data = await apiService.get<PaginatedItems<CoinBoxHistoryItem[]>>(url, {
        exceptionHandlingType: ExceptionHandlingType.AUTOMATIC,
      });
      return data;
    },
    placeholderData: keepPreviousData,
    ...options,
  });
}
