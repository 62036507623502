export const DEFAULT_AVATAR =
  "https://static-cdn.jtvnw.net/user-default-pictures-uv/13e5fa74-defa-11e9-809c-784f43822e80-profile_image-300x300.png";
export const PRIZES_OUTSIDE_ADDITIONAL_PRIZES_COUNT = 3;

type PlatformUrls = {
  [key: string]: string;
};

export const HYUS_USERNAME = "hyuslive";
export const WATCH_GAMES_USERNAME = "watchgamestv";
export const PKLE_USERNAME = "pkle";
export const SAMJUNIOR_USERNAME = "samjuniortv";
export const WREWARDS_USERNAME = "wrewards";

export const Streamers_Usernames = [
  HYUS_USERNAME,
  WATCH_GAMES_USERNAME,
  PKLE_USERNAME,
  SAMJUNIOR_USERNAME,
  WREWARDS_USERNAME,
];

export enum STREAMING_PLATFORMS {
  TWITCH = "twitch",
  KICK = "kick",
}

export enum HouseGameType {
  MINES = "MINES",
  COINFLIP = "COINFLIP",
  BLACKJACK = "BLACKJACK",
  ROULETTE = "ROULETTE",
  WHEEL = "WHEEL",
  PLINKO = "PLINKO",
  CRASH = "CRASH",
}

export const WatchGamesTVLinks: PlatformUrls = {
  discord: "https://discord.com/invite/watchgamestv",
  youtube: "https://www.youtube.com/@WatchGamesTV",
  twitter: "https://twitter.com/WatchGamesTV",
  kick: "https://kick.com/watchgamestv",
  twitch: "https://www.twitch.tv/watchgamestv",
  instagram: "https://www.instagram.com/ibbyyzekerie",
};

export const SamJuniorTVLinks: PlatformUrls = {
  discord: "https://discord.com/invite/HTPJVHEqyp",
  youtube: "https://www.youtube.com/@samjuniortv",
  twitter: "https://x.com/samjuniortv",
  kick: "https://kick.com/samjuniortv",
  twitch: "https://www.twitch.tv/samjuniortv",
  instagram: "https://www.instagram.com/samjuniortv",
};

export const HyusLinks: PlatformUrls = {
  discord: "https://discord.gg/9mCYGPuapv",
  youtube: "https://www.youtube.com/c/hyuslive",
  twitter: "https://twitter.com/hyuslive",
  kick: "https://kick.com/hyuslive",
  twitch: "https://www.twitch.tv/hyuslive",
  instagram: "https://www.instagram.com/hyuslive",
};

export const WRewardsLinks: PlatformUrls = {
  discord: WatchGamesTVLinks.discord,
  youtube: WatchGamesTVLinks.youtube,
  twitter: "https://twitter.com/WRewardscom",
  kick: "https://kick.com/wrewards",
  twitch: WatchGamesTVLinks.twitch,
  instagram: "https://www.instagram.com/wrewards",
};

export const PkleLinks: PlatformUrls = {
  discord: WatchGamesTVLinks.discord,
  youtube: "https://www.youtube.com/@PKLE",
  twitter: "https://twitter.com/lilpkle",
  kick: "https://kick.com/pkle",
  twitch: "https://www.twitch.tv/pkle",
  instagram: WRewardsLinks.instagram,
};

export const Streamers_Kick_Links = [
  WatchGamesTVLinks.kick,
  SamJuniorTVLinks.kick,
  WRewardsLinks.kick,
  PkleLinks.kick,
  HyusLinks.kick,
];

export enum ALL_ADMIN_ROLES {
  SUPER_ADMIN = "SuperAdmin",
  LEADERBOARD_ADMIN = "LeaderboardAdmin",
  POINTS_SHOP_ADMIN = "PointsShopAdmin",
  PACHINKO_ADMIN = "PachinkoAdmin",
  RAFFLE_ADMIN = "RaffleAdmin",
  PICK_A_GUEST_ADMIN = "PickAGuestAdmin",
  SITE_REWARD_ADMIN = "SiteRewardAdmin",
  USERS_ADMIN = "UsersAdmin",
  CALENDAR_ADMIN = "CalendarAdmin",
  CASE_ADMIN = "CaseAdmin",
  SLOT_REVIEW_ADMIN = "SlotReviewAdmin",
}

export const ADMIN_ROLES = [
  { name: ALL_ADMIN_ROLES.SUPER_ADMIN, id: 2 },
  { name: ALL_ADMIN_ROLES.LEADERBOARD_ADMIN, id: 3 },
  { name: ALL_ADMIN_ROLES.POINTS_SHOP_ADMIN, id: 4 },
  { name: ALL_ADMIN_ROLES.PACHINKO_ADMIN, id: 5 },
  { name: ALL_ADMIN_ROLES.RAFFLE_ADMIN, id: 6 },
  { name: ALL_ADMIN_ROLES.PICK_A_GUEST_ADMIN, id: 7 },
  { name: ALL_ADMIN_ROLES.SITE_REWARD_ADMIN, id: 8 },
  { name: ALL_ADMIN_ROLES.USERS_ADMIN, id: 9 },
  { name: ALL_ADMIN_ROLES.CALENDAR_ADMIN, id: 10 },
];

type RoleName =
  | "LeaderboardAdmin"
  | "UsersAdmin"
  | "RaffleAdmin"
  | "PachinkoAdmin"
  | "PickAGuestAdmin"
  | "SiteRewardAdmin"
  | "PointsShopAdmin"
  | "CalendarAdmin";

export const ROLE_TO_PERMISSIONS_MAPPING: Record<RoleName, string[]> = {
  [ALL_ADMIN_ROLES.LEADERBOARD_ADMIN]: [
    "Create, Edit, or Delete Leaderboards",
    "Ban or Unban Leaderboard Users",
    "Pick or Repick Random Winners",
  ],
  [ALL_ADMIN_ROLES.USERS_ADMIN]: [
    "Ban or Unban Users",
    "Change Users' Crypto and Steam Tradelink",
    "Change User Balance",
    "Add or Deduct Points to Users by Username",
  ],
  [ALL_ADMIN_ROLES.RAFFLE_ADMIN]: ["Create, Edit, or Delete Raffles", "Re-pick Winners"],
  [ALL_ADMIN_ROLES.PACHINKO_ADMIN]: ["Add, Update, or Delete Winners"],
  [ALL_ADMIN_ROLES.PICK_A_GUEST_ADMIN]: ["Pick a Guest for a Stream"],
  [ALL_ADMIN_ROLES.SITE_REWARD_ADMIN]: ["Create, Update, or Delete Site Rewards"],
  [ALL_ADMIN_ROLES.POINTS_SHOP_ADMIN]: [
    "Create, Update, or Delete Points Shop Items",
    "Review (Approve/Reject) Transactions (Purchases)",
    "Restock Items",
  ],
  [ALL_ADMIN_ROLES.CALENDAR_ADMIN]: ["Manage Calendars"],
};

export const ROLE_NAME_PRETTY_MAPPING: Record<string, string> = {
  [ALL_ADMIN_ROLES.SUPER_ADMIN]: "Super Admin",
  [ALL_ADMIN_ROLES.LEADERBOARD_ADMIN]: "Leaderboard Admin",
  [ALL_ADMIN_ROLES.USERS_ADMIN]: "Users Admin",
  [ALL_ADMIN_ROLES.RAFFLE_ADMIN]: "Raffle Admin",
  [ALL_ADMIN_ROLES.PACHINKO_ADMIN]: "Pachinko Admin",
  [ALL_ADMIN_ROLES.PICK_A_GUEST_ADMIN]: "Stream Guests Admin",
  [ALL_ADMIN_ROLES.SITE_REWARD_ADMIN]: "Site Reward Admin",
  [ALL_ADMIN_ROLES.POINTS_SHOP_ADMIN]: "Points Shop Admin",
  [ALL_ADMIN_ROLES.CALENDAR_ADMIN]: "Calendar Admin",
};

export const ROLE_TO_DESCRIPTION_MAPPING: Record<RoleName, string> = {
  [ALL_ADMIN_ROLES.LEADERBOARD_ADMIN]: "This Admin is responsible for managing leaderboards.",
  [ALL_ADMIN_ROLES.USERS_ADMIN]: "This Admin is responsible for managing users.",
  [ALL_ADMIN_ROLES.RAFFLE_ADMIN]: "This Admin is responsible for managing raffles.",
  [ALL_ADMIN_ROLES.PACHINKO_ADMIN]: "This Admin is responsible for managing pachinko.",
  [ALL_ADMIN_ROLES.PICK_A_GUEST_ADMIN]:
    "This Admin is responsible for picking a guest for a stream.",
  [ALL_ADMIN_ROLES.SITE_REWARD_ADMIN]: "This Admin is responsible for managing site rewards.",
  [ALL_ADMIN_ROLES.POINTS_SHOP_ADMIN]:
    "This Admin is responsible for managing points shop items and transactions.",
  [ALL_ADMIN_ROLES.CALENDAR_ADMIN]: "This Admin is responsible for managing calendars.",
};

export const ROLE_TO_PATH_MAPPING: Record<string, string> = {
  [ALL_ADMIN_ROLES.SUPER_ADMIN]: "/admin",
  [ALL_ADMIN_ROLES.LEADERBOARD_ADMIN]: "/admin/leaderboard",
  [ALL_ADMIN_ROLES.USERS_ADMIN]: "/admin/users",
  [ALL_ADMIN_ROLES.RAFFLE_ADMIN]: "/admin/raffles",
  [ALL_ADMIN_ROLES.PACHINKO_ADMIN]: "/admin/pachinko",
  [ALL_ADMIN_ROLES.PICK_A_GUEST_ADMIN]: "/admin/stream-guests",
  [ALL_ADMIN_ROLES.SITE_REWARD_ADMIN]: "/admin/site-rewards",
  [ALL_ADMIN_ROLES.POINTS_SHOP_ADMIN]: "/admin/points-shop",
  [ALL_ADMIN_ROLES.CALENDAR_ADMIN]: "/admin/calendar",
};

export const PATH_TO_ROLES_MAPPING: Record<string, string[]> = {
  "/admin": [ALL_ADMIN_ROLES.LEADERBOARD_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN], // admin/index.tsx is leaderboard page
  "/admin/permissions": [ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/leaderboard": [ALL_ADMIN_ROLES.LEADERBOARD_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/users": [ALL_ADMIN_ROLES.USERS_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/users/[id]": [ALL_ADMIN_ROLES.USERS_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/contact-requests": [ALL_ADMIN_ROLES.USERS_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/raffles": [ALL_ADMIN_ROLES.RAFFLE_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/raffles/[raffleId]": [ALL_ADMIN_ROLES.RAFFLE_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/raffles/history": [ALL_ADMIN_ROLES.RAFFLE_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/pachinko": [ALL_ADMIN_ROLES.PACHINKO_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/stream-guests": [ALL_ADMIN_ROLES.PICK_A_GUEST_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/site-rewards": [ALL_ADMIN_ROLES.SITE_REWARD_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/points-shop": [ALL_ADMIN_ROLES.POINTS_SHOP_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/points-shop/transactions": [
    ALL_ADMIN_ROLES.POINTS_SHOP_ADMIN,
    ALL_ADMIN_ROLES.SUPER_ADMIN,
  ],
  "/admin/calendar": [ALL_ADMIN_ROLES.CALENDAR_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/gallery": [
    ALL_ADMIN_ROLES.CALENDAR_ADMIN,
    ALL_ADMIN_ROLES.SUPER_ADMIN,
    ALL_ADMIN_ROLES.RAFFLE_ADMIN,
    ALL_ADMIN_ROLES.CASE_ADMIN,
  ],
  "/admin/lootbox": [ALL_ADMIN_ROLES.CASE_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/lootbox-item": [ALL_ADMIN_ROLES.CASE_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/lootbox-transaction": [ALL_ADMIN_ROLES.CASE_ADMIN, ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/slot": [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.SLOT_REVIEW_ADMIN],
  "/admin/slot-provider": [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.SLOT_REVIEW_ADMIN],
  "/admin/games/mines": [ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/games/coinflip": [ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/games/roulette": [ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/games/rates": [ALL_ADMIN_ROLES.SUPER_ADMIN],
  "/admin/users-multi": [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.USERS_ADMIN],
  "/admin/black-list": [ALL_ADMIN_ROLES.SUPER_ADMIN, ALL_ADMIN_ROLES.USERS_ADMIN],
};

export enum SocketEvent {
  // raffles
  AuthError = "AuthError",
  RaffleRouletteStarts = "RaffleRouletteStarts",
  RaffleStartOneRoulette = "RaffleStartOneRoulette",
  RaffleEnded = "RaffleEnded",
  NewRaffle = "NewRaffle",
  NewRaffleEntry = "NewRaffleEntry",
  RaffleEndsSoon = "RaffleEndsSoon",
  // streams
  StreamsStatusesCheck = "StreamsStatusesCheck",
  StreamStatusNotify = "StreamStatusNotify",
  NotifyNewCalendarDay = "NotifyNewCalendarDay",
  // stream guests
  StreamGuestsDashboardUpdate = "StreamGuestsDashboardUpdate",
  // dashboard
  DashboardStatsUpdate = "DashboardStatsUpdate",
  // points shop
  PointsShopItemCreated = "PointsShopItemCreated",
  PointsShopItemUpdated = "PointsShopItemUpdated",
  PointsShopItemDeleted = "PointsShopItemDeleted",
  PointsShopItemsRestocked = "PointsShopItemsRestocked",
  PointsShopItemPurchased = "PointsShopItemPurchased",
  PointsShopDashboardStatsUpdate = "PointsShopDashboardStatsUpdate",
  // bet
  LiveBets = "LiveBets",
  // rates
  RatesUpdate = "RatesUpdate",
  // personal
  BalanceUpdate = "BalanceUpdate",
  // utils
  NotifyNewNotification = "NotifyNewNotification",
}

export enum SocketMessage {
  //bet
  LiveBetsMessage = "LiveBetsMessage",
  ClientBalanceUpdate = "ClientBalanceUpdate",
}

export enum WheelSocketEvent {
  WheelInfo = "WheelInfo",
  WheelState = "WheelState",
  WheelHistory = "WheelHistory",
  WheelEnded = "WheelEnded",
  WheelPlayerBet = "WheelPlayerBet",
  WheelError = "WheelError",
}

export enum CrashSocketEvent {
  CrashState = "CrashState",
  AuthError = "AuthError",
  CrashError = "CrashError",
  CrashPlayerBet = "CrashPlayerBet",
  CrashPlayerCashout = "CrashPlayerCashout",
  CrashActiveGameBettorsResponse = "CrashActiveGameBettorsResponse",
}

export enum CrashSocketMessage {
  CrashJoin = "CrashJoin",
  CrashLeave = "CrashLeave",
  CrashRegisterBet = "CrashRegisterBet",
  CrashPlaceBet = "CrashPlaceBet",
  CrashPlaceBets = "CrashPlaceBets",
  CrashCashout = "CrashCashout",
  CrashCancelBetRegistration = "CrashCancelBetRegistration",
  CrashActiveGameBettorsRequest = "CrashActiveGameBettorsRequest",
  CrashGetLastGames = "CrashGetLastGames",
}

export enum WheelSocketMessage {
  WheelJoin = "WheelJoin",
  WheelGetInfo = "WheelGetInfo",
  WheelLeave = "WheelLeave",
  WheelPlaceBet = "WheelPlaceBet",
}

export enum CoinBoxSocketMessage {
  CoinBoxJoinRoom = "CoinBoxJoinRoom",
  CoinBoxLeaveRoom = "CoinBoxLeaveRoom",
}

export enum CoinBoxSocketEvent {
  CoinBoxOpened = "CoinBoxOpened",
}

export enum ADVENT_DAY_STATUS {
  Past = "past",
  Current = "current",
  Future = "future",
}

export enum TRANSACTION_STATUS {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export const CALENDAR_API_CODES = {
  ADV_CALENDAR_NOT_TODAY: "ADV_CALENDAR_NOT_TODAY",
  ADV_CALENDAR_DAY_ACTIVATED: "ADV_CALENDAR_DAY_ACTIVATED",

  ADV_CALENDAR_DAY_NOT_FOUND: "ADV_CALENDAR_DAY_NOT_FOUND",

  ADV_CALENDAR_RAFFLE_DOES_NOT_EXIST: "ADV_CALENDAR_RAFFLE_DOES_NOT_EXIST",

  ADV_CALENDAR_DISCORD_VERIFICATION_REQUIRED: "ADV_CALENDAR_DISCORD_VERIFICATION_REQUIRED",

  ADV_CALENDAR_GAMDOM_REQUIRED: "ADV_CALENDAR_GAMDOM_REQUIRED",

  ADV_CALENDAR_UNKNOWN_RULE_TYPE: "ADV_CALENDAR_UNKNOWN_RULE_TYPE",
};

export enum ADVENT_DAY_PRIZE_TYPE {
  RAFFLE = "raffle",
  POINTS = "points",
}

export enum ADVENT_CALENDAR_THEME {
  CHRISTMAS = "christmas",
  WINTER = "winter",
  PATRIC = "patric",
  EASTER = "easter",
  MAY = "may",
  JUNE = "june",
  SEPTEMBER = "september",
  OCTOBER = "october",
  NOVEMBER = "november",
  DECEMBER = "december",
}

export type UserConnectionType =
  | "DISCORD_ID"
  | "DISCORD_USERNAME"
  | "STEAM_PARTNER_ID"
  | "GAMDOM_ID"
  | "EMAIL"
  | "BTC_ADRESS"
  | "ETH_ADRESS"
  | "LTC_ADRESS"
  | "USDT_ERC20_ADRESS";

export const ConnectionSelectionOptions = [
  {
    value: "All",
    title: "All",
  },
  {
    value: "DISCORD_ID",
    title: "Discord ID",
  },
  {
    value: "DISCORD_USERNAME",
    title: "Discord Username",
  },
  {
    value: "STEAM_PARTNER_ID",
    title: "Steam",
  },
  {
    value: "GAMDOM_ID",
    title: "Gamdom ID",
  },
  {
    value: "EMAIL",
    title: "Email",
  },
  {
    value: "BTC_ADRESS",
    title: "BTC",
  },
  {
    value: "ETH_ADRESS",
    title: "ETH",
  },
  {
    value: "LTC_ADRESS",
    title: "LTC",
  },
  {
    value: "USDT_ERC20_ADRESS",
    title: "USDT",
  },
];

export enum BlackjackPlayerActions {
  HIT = "HIT",
  STAND = "STAND",
  DOUBLE_DOWN = "DOUBLE_DOWN",
  SPLIT = "SPLIT",
  BUY_INSURANCE = "BUY_INSURANCE",
  REJECT_INSURANCE = "REJECT_INSURANCE",
  // to trigger init animation
  INIT_GAME = "INIT_GAME",
}

export enum BlackjackInsuranceStatus {
  INELIGIBLE = "INELIGIBLE",
  ELIGIBLE = "ELIGIBLE",
  BOUGHT_PAYS_OUT = "BOUGHT_PAYS_OUT",
  BOUGHT_DOES_NOT_PAY_OUT = "BOUGHT_DOES_NOT_PAY_OUT",
  REJECTED = "REJECTED",
}

export enum BlackjackOutcomes {
  LOSS = "LOSS",
  WIN = "WIN",
  PENDING = "PENDING",
  NONE = "NONE",
  BLACKJACK = "BLACKJACK",
  DRAW = "DRAW",
  INSURED = "INSURED",
}

export enum GameStatus {
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
  NOT_STARTED = "NOT_STARTED",
}

export const finishedStatuses = [
  BlackjackOutcomes.LOSS,
  BlackjackOutcomes.WIN,
  BlackjackOutcomes.DRAW,
  BlackjackOutcomes.BLACKJACK,
  BlackjackOutcomes.INSURED,
];
