import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import ChakraModal from "components/TheComponents/Modal";
import AvailableModal from "components/slot/AvailableModal";
import { useRouter } from "next/router";
import { useAppContext } from "./AppProvider";
import { ExceptionHandlingType } from "types/apiServiceTypes";
import { GeoCheckResponse } from "types/slots/dto";
import { GeoModalText, GeoRestrictedPage, GeoRestrictedPages } from "types/geo";

export interface IGeoRestrictionContext {
  isAllowed: boolean;
  refetchGeoRestriction: () => void;
}

export const GeoRestrictionContext = React.createContext<IGeoRestrictionContext>({
  isAllowed: true,
  refetchGeoRestriction: () => {},
});

const GeoRestrictionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { apiService } = useAppContext();
  const router = useRouter();

  const [isAllowed, setIsAllowed] = useState<boolean>(true);

  const getIsAllowedGeo = useCallback(async () => {
    const result = await apiService.get<GeoCheckResponse>(`/slots/geo-check`, {
      exceptionHandlingType: ExceptionHandlingType.MANUAL,
    });
    return result;
  }, [apiService]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { allowed } = await getIsAllowedGeo();
        setIsAllowed(allowed);
      } catch (error) {
        setIsAllowed(false);
      }
    }
    fetchData();
  }, [getIsAllowedGeo]);

  const refetchGeoRestriction = useCallback(async () => {
    const { allowed } = await getIsAllowedGeo();
    setIsAllowed(allowed);
  }, [getIsAllowedGeo]);

  const isRestrictedPage = useMemo(() => {
    return !isAllowed && GeoRestrictedPages.some((pageRoute) => router.asPath.includes(pageRoute));
  }, [isAllowed, router.asPath]);

  return (
    <GeoRestrictionContext.Provider
      value={{
        isAllowed,
        refetchGeoRestriction,
      }}
    >
      <GeoRestrictionWrapper data-blurred={isRestrictedPage}>
        {children}
        <ChakraModal
          showClose={false}
          isOpen={isRestrictedPage}
          onClose={() => {}}
          size="2xl"
          content={
            <AvailableModal
              text={GeoModalText[GeoRestrictedPage.GAMES].text}
              title={GeoModalText[GeoRestrictedPage.GAMES].title}
            />
          }
        />
      </GeoRestrictionWrapper>
    </GeoRestrictionContext.Provider>
  );
};

export function useGeoRestrictionContext() {
  const context = React.useContext(GeoRestrictionContext);
  if (context === undefined) {
    throw new Error("useGeoRestrictionContext must be used within a GeoRestrictionProvider");
  }
  return context;
}

const GeoRestrictionWrapper = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  &[data-blurred="true"] {
    filter: blur(10px);
  }
`;

export default GeoRestrictionProvider;
