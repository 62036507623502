import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "contexts";
import { FeatureFlag } from "helpers/types";
import { TimeFrequencyMs } from "types/time-frequency";

export default function useFeatureFlags() {
  const { apiService } = useAppContext();
  return useQuery<FeatureFlag[]>({
    queryKey: ["feature-flags"],
    queryFn: async () => {
      try {
        const response = await apiService.request("/feature", {
          method: "GET",
        });

        const data = await response?.json();

        if (!response?.ok || !data) {
          return Promise.reject(data);
        }

        return data ?? [];
      } catch (error) {
        return [];
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: TimeFrequencyMs.Hour,
  });
}
