import { DualRingInner, DualRingLoader } from "components/common";
import { SpinnerRingProps } from "components/common/types";
import { HTMLAttributes } from "react";
interface LoaderProps {
  outerRingProps?: SpinnerRingProps & HTMLAttributes<HTMLDivElement>;
  innerRingProps?: SpinnerRingProps & HTMLAttributes<HTMLDivElement>;
}

const Loader = ({ outerRingProps, innerRingProps }: LoaderProps) => {
  return (
    <DualRingLoader {...outerRingProps}>
      <DualRingInner {...innerRingProps} />
    </DualRingLoader>
  );
};

export default Loader;
