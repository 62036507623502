import React from "react";
import { $styles, $stylesReset, Button, ButtonBlue, Flex, IStyles } from "components/common";
import styled, { css } from "styled-components";
import Image from "next/image";
import { PointsShopItem } from "types/points-shop";

interface SuccessfulPurchaseLayoutProps {
  onClose: () => void;
  selectedItem: PointsShopItem;
}

const SuccessfulPurchaseLayout: React.FC<SuccessfulPurchaseLayoutProps> = ({
  onClose,
  selectedItem,
}): React.ReactElement => {
  const { imageUrl, title } = selectedItem;

  const ButtonFlex = styled(Flex)`
    margin-top: 15px !important;
    margin: auto;
  `;

  const AdaptiveItemImage = styled(Image)`
    @media (max-width: 450px) {
      width: 250px;
      height: 253px;
    }
  `;

  return (
    <Flex justify="center" column>
      <Title>CONGRATS’</Title>
      <Flex width="100%" align="center" column>
        <AdaptiveItemImage alt="item" width={320} height={323} src={imageUrl} />
        <Text>
          THIS <ItemSpan>{title}</ItemSpan> IS NOW YOURS!
        </Text>
        <ButtonFlex>
          <Button
            styles={css`
              margin: auto;
            `}
            onClick={onClose}
          >
            <ButtonBlue>Awesome!</ButtonBlue>
          </Button>
        </ButtonFlex>
      </Flex>
    </Flex>
  );
};

export default SuccessfulPurchaseLayout;

const Title = styled.div<IStyles>`
  box-sizing: border-box;
  background-image: linear-gradient(265.52deg, #4796f2 -1.78%, #47d3f2 85.44%);
  background-clip: text;
  -webkit-background-clip: text; /* For better browser compatibility */
  color: transparent;
  font-family: var(--font-family-golos);
  font-size: 40px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  text-align: center;
  width: 100%;
  ${$styles};
`;

const Text = styled.div<IStyles>`
  ${$stylesReset}
  font-family: var(--font-family-golos);
  width: 90%;
  margin: auto;
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  text-align: center;
  color: rgba(210, 234, 255, 1);
  ${$styles};
`;
const ItemSpan = styled.span<IStyles>`
  background-image: linear-gradient(265.52deg, #4796f2 -1.78%, #47d3f2 85.44%);
  background-clip: text;
  -webkit-background-clip: text; /* For better browser compatibility */
  color: transparent;
  font-family: var(--font-family-golos);
  width: 90%;
  margin: auto;
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  text-align: center;
  text-transform: uppercase;
  ${$styles};
`;
