import { useCallback, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { useLocalStorage } from "usehooks-ts";

const variants = {
  icon: css`
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background: rgba(171, 186, 219, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    transition: 0.3s all ease-in-out;
    color: rgb(171, 186, 219);
    padding: 0px;
    &.icon-xl {
      width: 48px;
      height: 48px;
      border-radius: 48px;

      &:active {
        transform: scale(1.1);
      }
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
      color: rgb(171, 186, 219);
    }
    &[data-active="true"],
    &:not(:disabled):hover {
      color: #00b2ff;
    }
  `,
  secondary: css`
    box-shadow: 0px 2px 0px 0px #2c8b0b;
    background: linear-gradient(0deg, #5ebd3d 0%, rgba(133, 229, 101, 0.01) 100%),
      var(--Colors-Expansive-Green, #5ebd3d);
    border: 1px solid #86e565;
    color: #131635;
    transition: all ease-in-out 0.2s;

    &:active {
      transform: scale(0.95);
      box-shadow: 0px 1px 0px 0px #2c8b0b;
    }
  `,
  primary: css`
    display: flex;
    justify-content: center;
    gap: 8px;
    box-shadow: 0px 2px 0px 0px #055ba0;
    background: linear-gradient(0deg, rgba(141, 221, 255, 0.01) 0%, #8dddff 100%),
      var(--pure-sky-100, #00b2ff);
    border: 1px solid #8dddff;
    color: #131635;
    transition: all ease-in-out 0.2s;

    &:active {
      transform: scale(0.95);
      box-shadow: 0px 1px 0px 0px #055ba0;
    }
  `,
  ["copper"]: css`
    border: 1px solid #f0a961;
    background: transparent;
  `,
  ["advent"]: css`
    padding: 10px;
    background: url(/images/AdventCalendar/presets/october/card-bg-w-2.svg),
      linear-gradient(318deg, #eb3700 18.79%, #ffa74f 64.58%, #ffa629 102.81%);
    border: 1px solid #eb3701;
    box-shadow: 0px 2px 0px 0px #eb3701;
  `,
  ["primary-ghost"]: css`
    background: transparent;
    border: 1px solid transparent;
    color: #055ba0;
    transition: transform ease-in-out 0.2s;
    line-height: normal;

    .active,
    &[data-active="true"],
    &:hover {
      background: linear-gradient(0deg, rgba(141, 221, 255, 0.01) 0%, #8dddff 100%),
        var(--pure-sky-100, #00b2ff);
      color: #13171f;
      border-color: #8dddff;
    }
    &:active {
      transform: scale(0.95);
    }
  `,
  ["secondary-ghost"]: css`
    background: transparent;
    border: 1px solid transparent;
    color: #5ebd3d;
    transition: transform ease-in-out 0.2s;
    line-height: normal;
    .active,
    &[data-active="true"],
    &:hover {
      background: linear-gradient(0deg, #5ebd3d 0%, rgba(133, 229, 101, 0.01) 100%),
        var(--Colors-Expansive-Green, #5ebd3d);
      color: #13171f;
      border-color: #86e565;
    }
    &:active {
      transform: scale(0.95);
    }
  `,
  ["primary-icon"]: css`
    border: 0;
    background-color: transparent;
    cursor: pointer;
    color: #d2eaff;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    svg {
      width: 20px;
      height: 20px;
    }

    &.active,
    &:hover {
      color: #3b8be9;
      background: var(--Dark-Rainy-Morning, #2a3046);
    }

    &:active {
      transform: scale(0.95);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #3b8be9;
      }
    }
  `,
  ["primary-icon-bordered"]: css`
    border: 1px solid;
    background-color: transparent;
    cursor: pointer;
    color: #00b2ff;
    padding: 8px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    svg {
      width: 16px;
      height: 16px;
    }

    &.active,
    &:hover {
      color: #3b8be9;
      background: var(--Dark-Rainy-Morning, #2a3046);
    }

    &:active {
      transform: scale(0.95);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #3b8be9;
      }
    }
  `,
  ["gray-icon-bordered"]: css`
    border: 1px solid;
    background-color: transparent;
    cursor: pointer;
    color: #ebecf380;
    padding: 8px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    svg {
      width: 16px;
      height: 16px;
    }

    &.active,
    &:hover {
      color: #3b8be9;
      background: var(--Dark-Rainy-Morning, #2a3046);
    }

    &:active {
      transform: scale(0.95);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #3b8be9;
      }
    }
  `,
  ["primary-icon-bordered-shape"]: css`
    position: relative;
    background-color: transparent;
    cursor: pointer;
    color: #00b2ff;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    svg {
      position: relative;
      z-index: 1;
      width: 16px;
      height: 16px;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: #1d2234;
      z-index: 0;
      inset: 0;
      border-radius: inherit;
      border: 1px solid #2a3046;
      transform: rotate(45deg);
    }

    &.active::before,
    &:hover::before {
      color: #3b8be9;
      background: var(--Dark-Rainy-Morning, #2a3046);
      border: 1px solid #3b8be9;
    }

    &:active {
      transform: scale(0.9);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #3b8be9;
      }
    }
  `,
  ["secondary-icon"]: css`
    border: 0;
    background: var(--Dark-Midnight, #141722);
    cursor: pointer;
    color: var(--clouds-35, rgba(171, 186, 219, 0.35));
    padding: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;

    svg {
      width: 20px;
      height: 20px;
    }

    &[data-active="true"] {
      color: #3b8be9;
    }
    &.active,
    &:hover {
      color: #3b8be9;
      background: var(--Dark-Rainy-Morning, #2a3046);
    }

    &:active {
      transform: scale(0.95);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: #3b8be9;
      }
    }
  `,
  ["ghost-neon"]: css`
    padding: 8px 24px;
    background: transparent;
    border: 2px solid transparent;
    border-radius: 60px;
    display: flex;
    align-items: center;
    transition: all ease-in-out 0.2s;

    .img {
      min-height: 32px;
      width: 100%;
      position: relative;
      z-index: 1;
    }

    &:hover,
    &.active {
      opacity: 1;
      background: #00b2ff0d;
      border-color: #00b2ff;
    }

    &:active {
      transform: scale(0.95);
    }
  `,
  ["game-action-gray"]: css`
    border-radius: 8px;
    box-shadow: 0px 1px 0px 0px #444c6c inset, 0px 2px 0px 0px #141928;
    background-color: #2a3046;
    color: var(--daylight-daylight-main, #d5e0f5);
    display: flex;
    justify-content: center;
    gap: 8px;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    font-family: var(--font-family-golos);
    font-weight: 500;
    font-size: 14px;
    &:active {
      transform: scale(0.98);
      box-shadow: 0px 0px 0px 0px #444c6c inset, 0px 0px 0px 0px #141928;
    }

    &:disabled {
      box-shadow: none;
      pointer-events: none;
      color: #abbadb40;
      background-color: #abbadb0d;
      svg {
        transition: color 0.2s ease-in-out;
        color: #abbadb40;
      }
    }
  `,
  ["game-action-blue"]: css`
    border-radius: 8px;
    display: flex;
    justify-content: center;
    gap: 8px;
    transition: all 0.2s ease-in-out;
    font-family: var(--font-family-golos);
    font-weight: 600;
    font-size: 14px;
    border: 1px solid transparent;

    color: #141722;
    box-shadow: 0px 2px 0px 0px #1963a9;
    background: linear-gradient(125deg, #00b2ff 1.18%, #009fd9 97.91%) padding-box,
      linear-gradient(180deg, #9ce9ff -3.13%, rgba(60, 211, 243, 0.6) 100%) border-box;

    &:active {
      transform: scale(0.98);
      box-shadow: 0px 1px 0px 0px #1963a9;
    }
    &:disabled {
      box-shadow: none;
      pointer-events: none;
      color: #abbadb40;
      background: #abbadb0d;
      svg {
        transition: color 0.2s ease-in-out;
        color: #abbadb40;
      }
    }
  `,
  ["game-action-red"]: css`
    border-radius: 8px;
    display: flex;
    justify-content: center;
    gap: 8px;
    transition: all 0.2s ease-in-out;
    font-family: var(--font-family-golos);
    font-weight: 600;
    font-size: 14px;
    border: 1px solid transparent;

    color: #141722;
    box-shadow: 0px 2px 0px 0px #770404;
    background: linear-gradient(125deg, #ea3838 1.18%, #a32525 97.91%) padding-box,
      linear-gradient(180deg, #ff5454 -3.13%, rgba(243, 60, 60, 0.6) 100%) border-box;

    &:active {
      transform: scale(0.98);
      box-shadow: 0px 1px 0px 0px #770404;
    }
    &:disabled {
      box-shadow: none;
      pointer-events: none;
      color: #abbadb40;
      background: #abbadb0d;
      svg {
        transition: color 0.2s ease-in-out;
        color: #abbadb40;
      }
    }
  `,
  ["game-action-green"]: css`
    border-radius: 8px;
    display: flex;
    justify-content: center;
    gap: 8px;
    transition: all 0.2s ease-in-out;
    font-family: var(--font-family-golos);
    font-weight: 600;
    font-size: 14px;
    border: 1px solid transparent;

    color: #141722;
    box-shadow: 0px 2px 0px 0px #047722;
    background: linear-gradient(125deg, #92e02f 1.18%, #15b760 97.91%) padding-box,
      linear-gradient(180deg, #b4ff54 -3.13%, rgba(21, 183, 96, 0.6) 100%) border-box;

    &:active {
      transform: scale(0.98);
      box-shadow: 0px 1px 0px 0px #047722;
    }
    &:disabled {
      box-shadow: none;
      pointer-events: none;
      color: #abbadb40;
      background: #abbadb0d;
      svg {
        transition: color 0.2s ease-in-out;
        color: #abbadb40;
      }
    }
  `,
  ["game-action-black"]: css`
    border-radius: 8px;
    display: flex;
    justify-content: center;
    gap: 8px;
    transition: all 0.2s ease-in-out;
    font-family: var(--font-family-golos);
    font-weight: 600;
    font-size: 14px;
    border: 1px solid transparent;

    color: #d5e0f5;
    box-shadow: 0px 2px 0px 0px #1b1b1b;
    background: linear-gradient(125deg, #3d3d3d 1.18%, hsl(0, 1.9607843137254901%, 10%) 97.91%)
        padding-box,
      linear-gradient(180deg, #3f3f3f -3.13%, rgba(26, 25, 25, 0.6) 100%) border-box;

    &:active {
      transform: scale(0.98);
      box-shadow: 0px 1px 0px 0px #1b1b1b;
    }
    &:disabled {
      box-shadow: none;
      pointer-events: none;
      color: #abbadb40;
      background: #abbadb0d;
      svg {
        transition: color 0.2s ease-in-out;
        color: #abbadb40;
      }
    }
  `,
  ["kick-action-green"]: css`
    border-radius: 8px;
    display: flex;
    justify-content: center;
    gap: 8px;
    transition: all 0.2s ease-in-out;
    font-family: var(--font-family-golos);
    font-weight: 600;
    font-size: 14px;
    border: 1px solid transparent;

    color: #1b2035;
    box-shadow: 0px 2px 0px 0px #1e6618;
    background: linear-gradient(125deg, #35f224 1.18%, #35f224 97.91%) padding-box,
      linear-gradient(180deg, #98ff8e -3.13%, rgba(53, 242, 36, 0.6) 100%) border-box;

    &:active {
      transform: scale(0.98);
      box-shadow: 0px 1px 0px 0px #1e6618;
    }
    &:disabled {
      box-shadow: none;
      pointer-events: none;
      color: #abbadb40;
      background: #abbadb0d;
      svg {
        transition: color 0.2s ease-in-out;
        color: #abbadb40;
      }
    }
  `,
  ["pachiko-action-purple"]: css`
    border-radius: 8px;
    display: flex;
    justify-content: center;
    gap: 8px;
    transition: all 0.2s ease-in-out;
    font-family: var(--font-family-golos);
    font-weight: 600;
    font-size: 14px;
    border: 1px solid transparent;

    color: #d5e0f5;
    box-shadow: 0px 2px 0px 0px #5d27c8;
    background: linear-gradient(125deg, #8e5dff 1.18%, #8e5dff 97.91%) padding-box,
      linear-gradient(180deg, #c0a5ff -3.13%, rgba(219, 141, 255, 0.6) 100%) border-box;

    &:active {
      transform: scale(0.98);
      box-shadow: 0px 1px 0px 0px #5d27c8;
    }
    &:disabled {
      box-shadow: none;
      pointer-events: none;
      color: #abbadb40;
      background: #abbadb0d;
      svg {
        transition: color 0.2s ease-in-out;
        color: #abbadb40;
      }
    }
  `,
  ["discord-action"]: css`
    border-radius: 8px;
    display: flex;
    justify-content: center;
    gap: 8px;
    transition: all 0.2s ease-in-out;
    font-family: var(--font-family-golos);
    font-weight: 600;
    font-size: 14px;
    border: 1px solid transparent;

    color: #1b2035;
    background: #6a77ff;
    box-shadow: 0px 2px 0px 0px #3642bd;

    &:active {
      transform: scale(0.98);
      box-shadow: 0px 1px 0px 0px #5d27c8;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.8;
    }

    &:not(:disabled):hover {
      opacity: 0.8;
    }
  `,
};

export type ButtonVariant = keyof typeof variants;

export const WrewardsButton = styled.button<{ variant?: ButtonVariant }>`
  padding: 16px;
  font-family: var(--font-family-golos);
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: normal;
  outline: none;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover:not(:disabled) {
    opacity: 0.9;
  }

  &:disabled:active {
    transform: none;
  }

  &:active:not(:disabled) {
    opacity: 1;
  }
  &[data-info="true"] {
    cursor: default;
    &:hover:not(:disabled) {
      opacity: 1;
    }
    &:active {
      transform: none;
    }
  }

  ${({ variant = "primary" }) =>
    // @ts-ignore
    variants?.[variant as t] ? variants?.[variant as t] : variants.primary}
`;

export default WrewardsButton;
