import React from "react";
import * as luxon from "luxon";
import styled, { css } from "styled-components";
import { TheButton, TheDatePicker, TheFieldset, IStyles } from "components";
import { useAppContext } from "contexts";
import { IDaoLeaderboard, IDtoLeaderboard, TheLeaderboardSourceKeys } from "interfaces";
import Image from "next/image";
import { DualRingInner, DualRingLoader, Flex } from "components/common";
import { ManagePrizesButton } from "./LeaderboardCreateLayout";
import { triggerNotification } from "components/TheComponents/Notification";
import ChakraModal from "components/TheComponents/Modal";
import { useDisclosure } from "@chakra-ui/react";
import LeaderboardPrizesModalLayout from "./LeaderboardPrizesModalLayout";
import { SlideSwitcher } from "components";
import VerifierProvider, { useVerifierContext } from "contexts/VerifierProvider";

export interface ILeaderboardEditFormProps {
  leaderboard: IDtoLeaderboard;
  onEdit: (item: IDaoLeaderboard) => void;
  onClose: () => void;
}

const LeaderboardEditForm: React.FC<ILeaderboardEditFormProps> = ({
  leaderboard,
  onEdit,
}): React.ReactElement => {
  const { accessFetch, accessURL } = useAppContext();
  const { verify } = useVerifierContext();

  const { isOpen: isPrizesOpen, onOpen: onOpenPrizes, onClose: onClosePrizes } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [payload, setPayload] = React.useState<IDaoLeaderboard | null>(null);
  // const [hasError, setHasError] = React.useState<string | null>(null);

  const leaderboardId = leaderboard?.id;

  React.useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const leaderboardResponse = await accessFetch(`/leaderboard/${leaderboardId}`);
        const leaderboardData = await leaderboardResponse?.json();
        const leaderboardItem = leaderboardData?.item;

        const preparedPayload = {
          casino: leaderboardItem?.casinoProvider ?? TheLeaderboardSourceKeys.gamdom,
          startDate: luxon.DateTime.fromISO(leaderboardItem.startDate).toISO(),
          duration: `${leaderboardItem.durationDays}`,
          maxPublicUsersCount: `${leaderboardItem.maxPublicUsersCount}`,
          prize1: `${leaderboardItem.prize1}`,
          prize2: `${leaderboardItem.prize2}`,
          prize3: `${leaderboardItem.prize3}`,
          randomPrizeThreshold: `${leaderboardItem.randomPrizeThreshold}`,
          randomPrizes: leaderboardItem.randomPrizes,
          additionalPrizes: leaderboardItem.additionalPrizes,
          prizeSelectionType: leaderboardItem.prizeSelectionType,
          ticketRate: +leaderboardItem.ticketRate,
        };
        setPayload(preparedPayload);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLeaderboard();
  }, [accessFetch, leaderboardId, setPayload, setIsLoading]);

  const numProps = { pattern: "^[d]*$", type: "text" };

  const endDate: string | null = React.useMemo(() => {
    if (payload) {
      const { startDate, duration } = payload;
      const date = startDate ? luxon.DateTime.fromISO(startDate) : null;
      const days = duration !== "" && Number.isInteger(+duration) ? +duration : 0;
      if (date?.isValid && days > 0) {
        const hours = days * 24;
        const endDate = date.plus({ hours }).minus({ seconds: 1 }).toUTC();
        return endDate.toISO();
      }
      return null;
    }
    return null;
  }, [payload]);

  const onDateChage = (key: string) => (value: string | null) => {
    if (!value) return;
    const date = luxon.DateTime.fromISO(value).startOf("day").toUTC().toISO();
    setPayload((prevState) => {
      if (!prevState) return null;
      return { ...prevState, [key]: date };
    });
  };

  const onTextChange = (key: string) => (event: React.ChangeEvent) => {
    event.preventDefault();
    const { target } = event;
    if (!(target instanceof HTMLInputElement)) return;
    const { value } = target as HTMLInputElement;

    setPayload((prevState) => {
      if (!prevState) return null;
      return { ...prevState, [key]: value };
    });
  };

  const doSave = async ({ otp }: { otp: string }) => {
    if (payload) {
      try {
        const leaderboardId = leaderboard.id;
        const url = accessURL(`/leaderboard/${leaderboardId}`) as URL;
        const response = await accessFetch(url.href, {
          method: "PATCH",
          headers: {
            "x-otp": otp,
          },
          body: JSON.stringify({
            casinoProvider: payload.casino,
            startDate: payload.startDate,
            durationDays: +payload.duration,
            maxPublicUsersCount: +payload.maxPublicUsersCount,
            prize1: +payload.prize1,
            prize2: +payload.prize2,
            prize3: +payload.prize3,
            randomPrizeThreshold:
              +payload.randomPrizeThreshold === 0 ? null : +payload.randomPrizeThreshold,
            additionalPrizes: payload.additionalPrizes.map((el) => ({
              prizeNumber: el.prizeNumber,
              amount: +el.amount,
            })),
            randomPrizes: payload.randomPrizes.map((el) => ({
              prizeNumber: el.prizeNumber,
              amount: +el.amount,
            })),
            prizeSelectionType: payload.prizeSelectionType,
            ticketRate: +payload.ticketRate,
          }),
        });
        const json = await response?.json();
        if (response && (response.status === 200 || response.status === 201)) {
          triggerNotification({ text: "Leaderboard Updated!", type: "success" });
          onEdit && onEdit(payload);
        } else {
          triggerNotification({ text: json.message, type: "error" });
        }
      } catch (error) {
        console.log({ error });
      }
    }
  };

  const onSaveClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const fieldsToValidate = [payload?.prize1, payload?.prize2, payload?.prize3, payload?.duration];
    const valid = fieldsToValidate.every(
      (field) => field && field !== "" && Number.isInteger(+field) && +field >= 0
    );

    const maxPublicUsersCount = payload?.maxPublicUsersCount;

    const isMaxUsersValid =
      maxPublicUsersCount &&
      maxPublicUsersCount !== "" &&
      Number.isInteger(+maxPublicUsersCount) &&
      +maxPublicUsersCount >= 10;

    if (valid && isMaxUsersValid) {
      verify(({ otp }) => doSave({ otp }));
    } else {
      triggerNotification({ text: "Validation failed", type: "error" });
    }
  };

  return (
    <Box>
      {isLoading ? (
        <Flex align="center" justify="center" padding="60px 0px">
          <DualRingLoader>
            <DualRingInner />
          </DualRingLoader>
        </Flex>
      ) : payload ? (
        <>
          <BoxItem
            styles={css`
              margin-bottom: 8px;
            `}
          >
            <SlideSwitcher
              centerSwitcher
              value={payload?.casino}
              onChange={(value: string) => setPayload({ ...payload, casino: value })}
            />
          </BoxItem>
          <BoxItem>
            <TheFieldset title="Start date">
              <TheDatePicker
                value={payload?.startDate ?? null}
                onChange={onDateChage("startDate")}
              />
            </TheFieldset>
          </BoxItem>
          <BoxItem>
            <TheFieldset title="Duration (days)">
              <input
                {...numProps}
                placeholder="Duration (days)"
                value={payload?.duration ?? ""}
                onChange={onTextChange("duration")}
              />
            </TheFieldset>
          </BoxItem>
          <BoxItem>
            <TheFieldset title="End date">
              <TheDatePicker value={endDate} readOnly />
            </TheFieldset>
          </BoxItem>
          <BoxItem>
            <TheFieldset title="Max Public Users">
              <input
                {...numProps}
                placeholder="Max Public Users"
                value={payload?.maxPublicUsersCount ?? ""}
                onChange={onTextChange("maxPublicUsersCount")}
              />
            </TheFieldset>
          </BoxItem>
          <Flex
            style={{
              border: "1px solid rgba(36, 39, 70, 0.5)",
              borderRadius: 8,
              cursor: "pointer",
            }}
            width="100%"
            background="rgba(36, 39, 70, 0.5)"
            height={37}
            align="center"
            onClick={onOpenPrizes}
            justify="center"
          >
            <Image
              style={{ marginRight: 8 }}
              src="/images/icons/add-icon.png"
              alt="plus"
              width={15}
              height={15}
            />
            <ManagePrizesButton>Add Prizes</ManagePrizesButton>
          </Flex>
          <BoxItem
            styles={css`
              margin-top: 8px;
            `}
          >
            <TheButton preset="blue" width={"100%"} onClick={onSaveClick}>
              Save item
            </TheButton>
          </BoxItem>
          <ChakraModal
            isOpen={isPrizesOpen}
            onClose={onClosePrizes}
            styles={css`
              max-height: 700px;
            `}
            size="2xl"
            title="ADD PRIZES"
            content={
              <LeaderboardPrizesModalLayout
                setPayload={setPayload}
                payload={payload}
                onClose={onClosePrizes}
              />
            }
          />
        </>
      ) : null}
    </Box>
  );
};

export const LeaderboardEditFormLayout: React.FC<ILeaderboardEditFormProps> = (props) => {
  return (
    <VerifierProvider>
      <LeaderboardEditForm {...props} />
    </VerifierProvider>
  );
};

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  max-width: 543px;

  ${({ styles }) => styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;

  ${({ styles }) => styles}
`;
