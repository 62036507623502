import styled from "styled-components";
import { css } from "styled-components";

export const InputButton = styled.button`
  cursor: pointer;
  font-family: var(--font-family-golos);
  background: var(--global-bg-dark-2);
  color: var(--daylight-daylight-main, #d5e0f5);
  border-radius: 6px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: 500;

  &.rounded {
    border-radius: 6px;
  }

  &[data-active="true"] {
    background: var(--cloudy);
    box-shadow: 0px 0px 0px 1px #464f6a;
    border: 1px solid #131620;
  }

  &:not(:disabled):hover {
    background: var(--cloudy);
  }

  &:not(:disabled):active {
    transform: scale(0.95);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    height: 44px;
    width: 44px;
    border-radius: 0;
  }
`;

export const InputButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 100%;

  ${InputButton} {
    user-select: none;
  }

  @media (max-width: 768px) {
    &[data-leftsided="true"] {
      gap: 2px;
      margin-left: -8px;
      height: 44px;
      & :first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        margin-left: 2px;
      }
    }
    &:not([data-leftsided="true"]) {
      gap: 2px;
      margin-right: -8px;
      height: 44px;
      & :last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-right: 2px;
      }
    }
  }

  .fit-content {
    width: fit-content;
  }

  .selected-toxic {
    background: var(--toxic-dark);
    border: solid 1px var(--toxic-color);
    color: white;

    &:not(:disabled):hover {
      background: var(--toxic-dark);
    }
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  font-family: var(--font-family-golos);

  & button {
    &:disabled {
      color: var(--input-disabled);
      cursor: not-allowed;
      opacity: 1;
    }
  }
  & input {
    &:disabled {
      color: var(--input-disabled);
      cursor: not-allowed;
      opacity: 1;
    }
  }
`;

const variants = {
  base: css`
    background: var(--global-bg-dark-1);
  `,
  primary: css`
    background: var(--pure-sky-color);
    input:disabled {
      pointer-events: none;
      color: var(--daylight-color);
    }
  `,
  small_primary: css`
    height: 36px;
    background: var(--pure-sky-color);
    padding: 2px 6px;

    input {
      font-size: 16px;
    }
  `,
};

type InputVariant = keyof typeof variants;

export const InputWrapper = styled.div<{
  errorText?: string;
  inError?: boolean;
  variant?: InputVariant;
}>`
  border: ${({ inError }) => (inError ? " 1px solid var(--color-error)" : "none")};
  padding: 8px;
  border-radius: 8px;
  height: 48px;
  margin-bottom: ${({ errorText }) => (errorText ? "16px" : "0")};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  position: relative;
  width: 100%;

  ${({ errorText }) =>
    errorText &&
    css`
      &::after {
        width: 100%;
        height: 25px;
        left: 0;
        bottom: -25px;
        position: absolute;
        display: inline-block;
        color: var(--color-error);
        content: "${errorText}";
        white-space: nowrap;
        font-size: 11px;
      }
    `}

  & > input {
    font-family: var(--font-family-golos);
    color: var(--daylight-color);
    min-width: 2ch;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    background: transparent;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:disabled {
    ${InputButton} {
      pointer-events: none;
    }
  }
  ${({ variant = "base" }) =>
    variants?.[variant as InputVariant] ? variants?.[variant as InputVariant] : variants.base}
`;

export const Label = styled.div`
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  text-transform: capitalize;
  color: var(--daylight-color);
`;
