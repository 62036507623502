import React from "react";
import styled, { css } from "styled-components";
import { IStyles } from "components";

export type TTheFieldsetValue = string | number;

export interface ITheFieldset {
  children?: React.ReactElement;
  empty?: boolean;
  title?: string;
  valid?: boolean;
  disabled?: boolean;
  errorText?: string;
}

export const TheFieldset: React.FC<IStyles & ITheFieldset> = ({
  children,
  empty = false,
  title,
  valid,
  disabled,
  errorText,
  ...props
}): React.ReactElement => {
  const fieldsetProps: ITheFieldset = {
    ...(empty && { empty }),
    ...(typeof valid !== "undefined" && { valid }),
    ...(typeof disabled !== "undefined" && { disabled }),
    ...(typeof errorText !== "undefined" && { errorText }),
    ...props,
  };

  const legend = !empty && title ? <Legend data-active={!empty}>{title}</Legend> : null;

  return (
    <Fieldset {...fieldsetProps}>
      {legend}
      <Box>{children}</Box>
    </Fieldset>
  );
};

const Box = styled.div<IStyles>`
  & input[type="text"],
  & input[type="number"],
  & textarea {
    width: calc(100% - 30px);

    // text
    color: #d2eaff;
    font-family: var(--font-family-golos);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 150%;

    // background
    background: transparent;
    border: none;
    outline: none;
  }

  & textarea {
    width: 100%;
    resize: vertical;
    resize: none;
  }

  ${({ styles }) => styles}
`;

const Fieldset = styled.fieldset<IStyles & Pick<ITheFieldset, "empty" | "valid" | "errorText">>`
  box-sizing: border-box;
  margin-bottom: ${({ errorText }) => (errorText ? "16px" : "0")};
  position: relative;

  ${({ errorText }) =>
    errorText &&
    css`
      &::after {
    width: 100%;
    left: 0;
    height: 25px;
    bottom: -25px;
    position: absolute;
    display: inline-block;
    color: var(--color-error);
    content: "${errorText}"
    white-space: nowrap;
    font-size: 11px;
  }
    `}

  // background
  background: #101622;
  border: 1px solid #3b8be9;
  border-radius: 4px;

  &:focus-within {
    border-color: #3b8be9;
  }

  & > input:-webkit-autofill {
    background-color: transparent !important;
    color: inherit;
  }

  ${({ valid }) =>
    valid === false
      ? css`
          border-color: #e93b3b;
        `
      : null}
  ${({ disabled }) =>
    disabled
      ? css`
          border: 1px solid rgba(210, 234, 255, 0.5);
          opacity: 0.3;
        `
      : null}

  & *:disabled {
    color: rgba(210, 234, 255, 0.75);
  }

  ${({ styles }) => styles}
`;

const Legend = styled.legend<IStyles>`
  box-sizing: border-box;
  padding: 0;
  color: transparent;
  font-family: var(--font-family-golos);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  background-color: transparent;

  &[data-active="true"] {
    padding: 0 8px;
    color: rgba(210, 234, 255, 0.5);
    background-color: #151c2c;
  }

  ${({ styles }) => styles}
`;
