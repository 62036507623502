import CookieConsent from "react-cookie-consent";

import React from "react";
import { IStyles } from "components";
import { Button, ButtonBlue } from "components/common";
import { css } from "styled-components";
import WrewardsButton from "components/wrewards-ui-kit/Button";

export const CookieBanner: React.FC<IStyles> = (): React.ReactElement => {
  return (
    <CookieConsent
      location="bottom"
      cookieName="cookiesTrack"
      containerClasses="cookie-banner"
      buttonClasses="cookie-banner-button"
      buttonWrapperClasses="cookie-banner-button-wrapper"
      buttonText="Accept"
    >
      By clicking «Accept», you agree to the storing of cookies on your device to enhance site
      navigation, analyze site usage, and assist in our marketing efforts.
    </CookieConsent>
  );
};
