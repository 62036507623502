/* eslint-disable react/display-name */
import { Button, ButtonBlue, Flex } from "components/common";
import { formatNumber } from "components/pachinko/Prizes";
import { TheIcon } from "components/TheComponents";
import Image from "next/image";
import React, { forwardRef, HTMLAttributes, CSSProperties, useState } from "react";
import styled, { css } from "styled-components";
import { SubHeader, Card } from "./ItemsTable";
import apiService from "services/apiService";
import { Circle } from "@chakra-ui/react";
import { ExceptionHandlingType } from "types/apiServiceTypes";
import WrewardsActionButton from "components/wrewards-ui-kit/Button/WrewardsActionButton";
import Favorite from "assets/icons/favorite.svg";
import Flames from "assets/icons/flames.svg";
import { triggerNotification } from "components/TheComponents/Notification";
import { PointsShopItem } from "types/points-shop";

const ItemQuantity = styled(Flex)`
  align-items: center;
  gap: 8px;
  color: #abbadb;
  font-family: "Golos Text";
  font-size: 14px;
  line-height: 150%; /* 21px */
  margin-top: 24px;
`;

const ItemQuantityPrice = styled.div`
  color: #131635;
  font-family: "Golos Text";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
`;

const ItemLabel = styled.div`
  position: absolute;
  display: inline-flex;
  min-width: 122px;
  height: 32px;
  padding: 8px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 0px 0px 8px 8px;
  background: linear-gradient(98deg, #f32257 20.16%, #d21587 62.28%, #8719dc 132.48%);

  color: #d5e0f5;
  font-family: "Golos Text";
  font-size: 14px;
`;

export type ItemСomponentProps = HTMLAttributes<HTMLDivElement> & {
  itemId: number;
  withOpacity?: boolean;
  isDragging?: boolean;
  onSelectItem?: (item: PointsShopItem) => void;
  onOpenDelete?: () => void;
  setSelectedItem?: React.Dispatch<any>;
  isAdminPage?: boolean;
  disableDND?: boolean;
  item: PointsShopItem;
  fetchData: () => void;
  profile: any;
  onOpenLogin: () => void;
};

const Item = forwardRef<HTMLDivElement, ItemСomponentProps>(
  (
    {
      id,
      withOpacity,
      isDragging,
      style,
      item,
      onSelectItem,
      onOpenDelete,
      setSelectedItem,
      isAdminPage,
      disableDND,
      fetchData,
      profile,
      onOpenLogin,
      ...props
    },
    ref
  ) => {
    const inlineStyles: CSSProperties = {
      opacity: withOpacity ? "0.5" : "1",
      transformOrigin: "50% 50%",
      cursor: !disableDND ? (isDragging ? "grabbing" : "grab") : "default",
      display: "flex",
      color: "black",
      width: "100%",
      alignItems: "center",
      boxShadow: isDragging
        ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
        : "none",
      transform: isDragging ? "scale(1.05)" : "none",
      ...style,
    };

    const [favorite, setFavorite] = useState<boolean | undefined>(item.isFavorite);
    const [isFavLoading, setIsFavLoading] = useState<boolean>(false);

    const handleIsFavoriteItem = async (e: React.MouseEvent, id: number) => {
      e.stopPropagation();
      if (!profile) {
        onOpenLogin();
      } else {
        try {
          setIsFavLoading(true);
          const result = await apiService.post<number>(
            `/point-shop/${favorite ? "remove-favorite" : "add-favorite"}/${id}`,
            undefined,
            {
              exceptionHandlingType: ExceptionHandlingType.AUTOMATIC,
            }
          );

          if (result) {
            triggerNotification({
              text: `Item ${favorite ? "removed from " : "added to "} favorite`,
              type: favorite ? "warning" : "success",
            });
          }

          setFavorite(!favorite);
        } finally {
          setIsFavLoading(false);
        }
      }
    };

    const itemSoldSum = item.stockQuantity - item.quantity;

    return (
      <Card ref={ref} style={inlineStyles} {...props}>
        {item.quantity == item.stockQuantity && (
          <ItemLabel>
            <Flames />
            Limited offer
          </ItemLabel>
        )}

        <WrewardsActionButton
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            zIndex: 1,
            width: 36,
            height: 36,
          }}
          isActive={favorite}
          variant="icon"
          onClick={(e) => handleIsFavoriteItem(e, item.id)}
          isLoading={isFavLoading}
          loaderProps={{ smallRing: true }}
        >
          <Favorite />
        </WrewardsActionButton>

        <Flex align="center" width="100%" column height="100%" gap={4}>
          <Image
            src={item.imageUrl ? item.imageUrl : "/images/PointShopComponent/w-logo.png"}
            height={144}
            width={144}
            alt="image"
          />

          <SubHeader
            style={{
              height: 42,
              paddingBottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            showTooltip={item.title.length > 10 || false}
            title={item.title}
          >
            {item.title.length > 30 ? item.title.slice(0, 30) + "..." : item.title}
          </SubHeader>

          {isAdminPage ? (
            <Flex>
              <Button
                styles={css`
                  width: 120%;
                  cursor: pointer;
                  white-space: nowrap;
                  height: 44px;
                  margin-right: 10px;
                `}
                preset="blue"
                isDark
                onClick={() => {
                  onSelectItem?.(item);
                }}
              >
                <ButtonBlue isDark>Edit item</ButtonBlue>
              </Button>
              <Button
                styles={css`
                  cursor: pointer;
                  height: 44px;
                  padding: 10px;
                `}
                preset="blue"
                isDark
                onClick={() => {
                  onOpenDelete?.();
                  setSelectedItem?.(item);
                }}
              >
                <ButtonBlue isDark>
                  {" "}
                  <TheIcon icon="action:remove" size={16} />
                </ButtonBlue>
              </Button>
            </Flex>
          ) : (
            <Button
              styles={css`
                min-width: 180px;
                height: 46px;
                margin-top: auto;
                font-size: 14px;
                padding: 10px 15px;
              `}
              preset="blue"
              isDark={item.quantity === 0 || false}
              disabled={item.quantity === 0 || false}
              onClick={() => {
                if (item.quantity > 0) {
                  onSelectItem?.(item);
                }
              }}
            >
              <ButtonBlue
                isDark={item.quantity === 0 || false}
                transparent
                style={{ whiteSpace: "nowrap" }}
              >
                {item.quantity === 0 ? "Sold Out" : "Buy for"}
              </ButtonBlue>

              <Flex align="center" gap={4}>
                <Image
                  src="/images/PointShopComponent/coin_2.svg"
                  height={24}
                  width={24}
                  alt="image"
                />

                <ItemQuantityPrice style={{ color: item.quantity !== 0 ? "#131635" : "#8999ab" }}>
                  {formatNumber(item.price)}
                </ItemQuantityPrice>
              </Flex>
            </Button>
          )}

          <ItemQuantity>
            {item.quantity > 0 && `${item.quantity} Left`}

            {item.quantity > 0 && itemSoldSum > 0 && (
              <Circle
                minWidth={1}
                minHeight={1}
                backgroundColor={"rgba(171, 186, 219, 0.50)"}
              ></Circle>
            )}

            {itemSoldSum > 0 && (
              <span
                style={{ color: "rgba(171, 186, 219, 0.50)" }}
              >{`Sold ${itemSoldSum} times`}</span>
            )}
          </ItemQuantity>
        </Flex>
      </Card>
    );
  }
);

export default Item;
