import React from "react";
import { useDropzone } from "react-dropzone";
import styled, { css } from "styled-components";

import { IStyles, $styles, Button, ButtonBlue } from "components";
import { Flex } from "components/common";
import { IAppContext, withApp } from "contexts";
import Image from "next/image";
import { TheFieldset, TheIcon } from "components/TheComponents";
import { addCommasToNumber } from "helpers/addCommasToNumber";
import MFAModal from "components/ScreenComponent/MFAModals/MFAModal";
import QRСodeModal from "components/ScreenComponent/MFAModals/QRСodeModal";
import { triggerNotification } from "components/TheComponents/Notification";
import GalleryDropdown from "components/common/GalleryDropdown";
import { PointsShopItem } from "types/points-shop";

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  ${$styles}
`;

interface AdminActionModalProps {
  onClose: () => void;
  fetchData: () => void;
  selectedItem: PointsShopItem;
}

interface ItemToCreate {
  title: string;
  description: string;
  quantity: number;
  stockQuantity: number;
  price: number;
  price_dollars: number;
  userCooldown: number;
  imageUrl: string;
}

interface ItemToEdit {
  title: string;
  description: string;
  quantity: number;
  stockQuantity: number;
  price: number;
  price_dollars: number;
  userCooldown: number;
  createdBy: string;
  imageUrl?: string;
}

const AdminActionLayout: React.FC<AdminActionModalProps & IAppContext> = ({
  onClose,
  selectedItem,
  profile,
  setShowQrModal,
  showQrModal,
  fetchData,
}): React.ReactElement => {
  const isMfaEnabled = !!profile?.mfaSecret;
  const [showVerifyModal, setShowVerifyModal] = React.useState<boolean>(false);
  const [payload, setPayload] = React.useState<ItemToEdit | ItemToCreate>(
    selectedItem
      ? {
          title: selectedItem.title,
          description: selectedItem.description,
          quantity: +selectedItem.quantity,
          price: selectedItem.price,
          price_dollars: selectedItem.price_dollars,
          userCooldown: +selectedItem.userCooldown,
          createdBy: selectedItem.createdBy,
          stockQuantity: selectedItem.stockQuantity,
          imageUrl: selectedItem.imageUrl,
        }
      : {
          title: "",
          description: "",
          quantity: 0,
          price: 0,
          stockQuantity: 20,
          price_dollars: 0,
          userCooldown: 0,
          imageUrl: "",
        }
  );
  const [filePreview, setFilePreview] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const { acceptedFiles, open, getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [] },
    onDrop: (acceptedFiles) => {
      setFilePreview(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        }).preview
      );
    },
    maxFiles: 1,
  });

  const numProps = { pattern: "^[d]*$", type: "text" };

  const [errors, setErrors] = React.useState<{
    [key in keyof (ItemToEdit | ItemToCreate)]?: string;
  }>({});

  const validateForm = () => {
    const newErrors: { [key in keyof (ItemToEdit | ItemToCreate)]?: string } = {};

    if (!payload.title) {
      newErrors.title = "Title is required";
    }
    if (!payload.description) {
      newErrors.description = "Description is required";
    }
    if (!payload.imageUrl && !acceptedFiles[0]) {
      newErrors.imageUrl = "Add photo";
    }
    if (!payload.price) {
      newErrors.price = "Price invalid";
    }
    if (payload.quantity < 0) {
      newErrors.quantity = "Quantity invalid";
    }

    if (!payload.stockQuantity) {
      newErrors.stockQuantity = "Stock quantity required";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const createOrUpdateItem = async ({ otp }: { otp: string }) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      for (const [key, value] of Object.entries(payload)) {
        if (key !== "imageUrl") {
          formData.append(key, String(value));
        }
      }
      if (acceptedFiles[0]) {
        formData.append("file", acceptedFiles[0]);
      } else if (payload.imageUrl) {
        formData.append("imageUrl", payload.imageUrl);
      }

      const urlOrigin: string = process.env.NEXT_PUBLIC_APP_BE_ORIGIN ?? "http://localhost:4000";
      const response = await fetch(
        selectedItem
          ? `${urlOrigin}/point-shop/items/${selectedItem.id}`
          : `${urlOrigin}/point-shop/items`,
        {
          method: selectedItem ? "PUT" : "POST",
          headers: {
            "x-otp": otp,
          },
          body: formData,
          credentials: "include",
        }
      );
      const data = await response?.json();
      if (response && (response.status === 200 || response.status === 201)) {
        triggerNotification({
          text: selectedItem ? "Item Updated!" : "Item Created!",
          type: "success",
        });
        onClose();
        fetchData();
      } else {
        triggerNotification({ text: data?.message, type: "error" });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  return (
    <Flex column>
      <BoxItem style={{ marginBottom: 20 }}>
        <TheFieldset valid={!errors.title} title="Name">
          <input
            {...numProps}
            placeholder={errors.title ? errors.title : "Item name"}
            value={payload.title}
            onChange={(e) =>
              setPayload((prevPayload) => ({
                ...prevPayload,
                title: e.target.value,
              }))
            }
          />
        </TheFieldset>
      </BoxItem>
      <BoxItem style={{ marginBottom: 20 }}>
        <TheFieldset valid={!errors.description} title="Caption">
          <input
            {...numProps}
            placeholder={errors.description ? errors.description : "Item description"}
            value={payload.description}
            onChange={(e) =>
              setPayload((prevPayload) => ({
                ...prevPayload,
                description: e.target.value,
              }))
            }
          />
        </TheFieldset>
      </BoxItem>
      <BoxItem style={{ marginBottom: 20 }}>
        <TheFieldset valid={!errors.price} title="Price in points">
          <input
            {...numProps}
            placeholder={errors.price ? errors.price : "Item Price in points"}
            value={addCommasToNumber(payload.price.toString())}
            onChange={(e) => {
              const input = e.target.value;
              const digitsOnly = input.replace(/\D/g, ""); // Filter out non-digit characters
              setPayload((prevPayload) => ({
                ...prevPayload,
                price: +digitsOnly,
              }));
            }}
          />
        </TheFieldset>
      </BoxItem>
      <BoxItem style={{ marginBottom: 20 }}>
        <TheFieldset valid={!errors.price_dollars} title="Price in $">
          <input
            {...numProps}
            placeholder={errors.price_dollars ? errors.price_dollars : "Item Price in $"}
            value={addCommasToNumber(payload.price_dollars.toString())}
            onChange={(e) => {
              const input = e.target.value;
              const digitsOnly = input.replace(/\D/g, ""); // Filter out non-digit characters
              setPayload((prevPayload) => ({
                ...prevPayload,
                price_dollars: +digitsOnly,
              }));
            }}
          />
        </TheFieldset>
      </BoxItem>
      <BoxItem style={{ marginBottom: 20 }}>
        <Flex width="100%">
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{ width: "30%", height: "112px" }}
          >
            <input {...getInputProps()} />
            {(filePreview && <Image src={filePreview} alt="image" width={120} height={112} />) ||
              (payload.imageUrl && (
                <div
                  style={{
                    width: 120,
                    height: 112,
                    backgroundImage: `url(${payload.imageUrl})`,
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: " center 20%",
                    backgroundClip: "border-box",
                    borderRadius: 8,
                  }}
                />
              )) || (
                <div
                  style={{
                    width: 120,
                    height: 112,
                    backgroundColor: "#101622",
                    borderRadius: 8,
                  }}
                />
              )}
          </div>
          <Flex style={{ width: "70%" }} column>
            <TheFieldset
              valid={!errors.imageUrl}
              styles={css`
                width: 100%;
                margin: 0;
              `}
              title="Image"
            >
              <input
                {...numProps}
                placeholder={errors.imageUrl ? errors.imageUrl : "Image (Link)"}
                value={payload.imageUrl}
                onChange={(e) =>
                  setPayload((prevPayload) => ({
                    ...prevPayload,
                    imageUrl: e.target.value,
                  }))
                }
              />
            </TheFieldset>
            <Flex width="100%">
              <BoxItem
                styles={css`
                  margin-top: 8px;
                  width: 80%;
                `}
              >
                <Button
                  styles={css`
                    height: 50px;
                    background: #181f2e;
                    color: #99abbd;
                    box-shadow: none;
                  `}
                  onClick={open}
                  isDark
                >
                  <ButtonBlue isDark>Choose file</ButtonBlue>
                </Button>
              </BoxItem>

              <BoxItem
                styles={css`
                  margin-top: 8px;
                  width: 20%;
                `}
              >
                <Button
                  onClick={() => {
                    setFilePreview(null);
                  }}
                  style={{ padding: "6px 16px", height: 50, marginLeft: 10 }}
                  isDark
                >
                  <TheIcon icon="action:remove" size={16} />
                </Button>
              </BoxItem>
            </Flex>
            <GalleryDropdown
              onSelect={(imageUrl: string) =>
                setPayload((prevPayload) => ({ ...prevPayload, imageUrl }))
              }
            />
          </Flex>
        </Flex>
      </BoxItem>
      <BoxItem style={{ marginBottom: 20 }}>
        <TheFieldset valid={!errors.quantity} title="Item Quantity">
          <input
            {...numProps}
            placeholder={errors.quantity ? errors.quantity : "Item Quantity"}
            value={addCommasToNumber(payload.quantity.toString())}
            onChange={(e) => {
              const input = e.target.value;
              const digitsOnly = input.replace(/\D/g, ""); // Filter out non-digit characters
              setPayload((prevPayload) => ({
                ...prevPayload,
                quantity: +digitsOnly,
              }));
            }}
          />
        </TheFieldset>
      </BoxItem>
      <BoxItem style={{ marginBottom: 20 }}>
        <TheFieldset valid={!errors.stockQuantity} title="Stock Quantity">
          <input
            {...numProps}
            placeholder={errors.stockQuantity ? errors.stockQuantity : "Stock Quantity"}
            value={addCommasToNumber(payload.stockQuantity.toString())}
            onChange={(e) => {
              const input = e.target.value;
              const digitsOnly = input.replace(/\D/g, ""); // Filter out non-digit characters
              setPayload((prevPayload) => ({
                ...prevPayload,
                stockQuantity: +digitsOnly,
              }));
            }}
          />
        </TheFieldset>
      </BoxItem>
      <BoxItem style={{ marginBottom: 20 }}>
        <TheFieldset title="User Cooldown">
          <input
            {...numProps}
            placeholder="Cooldown"
            value={addCommasToNumber(payload.userCooldown.toString())}
            onChange={(e) => {
              const input = e.target.value;
              const digitsOnly = input.replace(/\D/g, ""); // Filter out non-digit characters
              setPayload((prevPayload) => ({
                ...prevPayload,
                userCooldown: +digitsOnly,
              }));
            }}
          />
        </TheFieldset>
      </BoxItem>
      <BoxItem
        styles={css`
          margin-top: 8px;
          height: 50px;
          @media (max-width: 600px) {
            width: 50%;
          }
        `}
      >
        <Button
          height={44}
          onClick={(e) => {
            e.preventDefault();
            if (validateForm()) {
              if (isMfaEnabled) {
                setShowVerifyModal(true);
              } else {
                setShowQrModal(true);
              }
            } else {
              triggerNotification({ text: "Validation failed", type: "error" });
            }
          }}
        >
          <ButtonBlue style={{ whiteSpace: "nowrap" }} transparent>
            {selectedItem ? "Edit Item" : "Save Item"}
          </ButtonBlue>
        </Button>
      </BoxItem>
      {isMfaEnabled && showVerifyModal && (
        <MFAModal
          isLoading={isLoading}
          onVerify={createOrUpdateItem}
          onClose={() => setShowVerifyModal(false)}
        />
      )}
      {showQrModal && <QRСodeModal onClose={() => setShowQrModal(false)} />}
    </Flex>
  );
};

export default withApp(AdminActionLayout);
