import { triggerNotification } from "components/TheComponents/Notification";
import { useAppContext } from "contexts";
import { useCallback } from "react";
import { AddFavoriteSlotAction, AddFavoriteSlotDto } from "types/slots/dto";

export function useSlotActions() {
  const { apiService } = useAppContext();

  const addFavoriteSlot = useCallback(
    ({ code, provider }: AddFavoriteSlotDto, action: AddFavoriteSlotAction) =>
      apiService.post(`/slots/${action}-favorite`, { code, provider }),
    [apiService]
  );

  const onAddFavoriteSlot = useCallback(
    async (dto: AddFavoriteSlotDto, action: AddFavoriteSlotAction, onSuccess?: () => any) => {
      try {
        await addFavoriteSlot(dto, action);
        onSuccess && (await onSuccess());
        const actionText = action === "add" ? "added to" : "removed from";
        const tiggerType = action === "add" ? "success" : "warning";
        triggerNotification({
          text: `Slot ${actionText} favorite`,
          type: tiggerType,
        });
      } catch (error: any) {
        triggerNotification({
          text: error?.message,
          type: "error",
        });
      }
    },
    [addFavoriteSlot]
  );

  return { addFavoriteSlot, onAddFavoriteSlot };
}
