import { ITheTableCell, TheTable } from "components/common";
import React from "react";
import { GameBet } from "../..";
import { Cell, TableHeader, TableWrapper } from "./styles";
import Image from "next/image";
import { DateTime } from "luxon";
import styled, { css } from "styled-components";

export type MyBetsTabTableProps = {
  bets: GameBet[];
};

const MyBetsTabTable: React.FC<MyBetsTabTableProps> = ({ bets }) => {
  const setup = React.useMemo(
    () => [
      {
        $key: "id",
        render: (props: ITheTableCell<GameBet, GameBet>) => {
          return <Cell>{props.cell}</Cell>;
        },
        title: <TableHeader>id</TableHeader>,
      },
      {
        $key: "gameType",
        render: (props: ITheTableCell<GameBet, GameBet>) => {
          return <Cell>{props.cell}</Cell>;
        },
        title: <TableHeader>game</TableHeader>,
      },
      {
        $key: "amount",
        render: (props: ITheTableCell<GameBet, GameBet>) => {
          return (
            <Cell withPoints>
              <Image src="/images/coins/g-coin.svg" alt="coin" width={24} height={24} />
              {props.cell}
            </Cell>
          );
        },
        title: <TableHeader>wager</TableHeader>,
      },
      {
        $key: "payout",
        render: (props: ITheTableCell<GameBet, GameBet>) => {
          return (
            <Cell withPoints>
              <Image src="/images/coins/g-coin.svg" alt="coin" width={24} height={24} />
              {props.cell}
            </Cell>
          );
        },
        title: <TableHeader>payout</TableHeader>,
      },
      {
        $key: "settledAt",
        render: (props: ITheTableCell<GameBet, GameBet>) => {
          return <Cell>{DateTime.fromISO(props.cell).toFormat("dd.MM.yyyy HH:mm")}</Cell>;
        },
        title: <TableHeader>date</TableHeader>,
      },
    ],
    []
  );
  return (
    <TableWrapper>
      <TheTable
        boxStyles={css`
          margin: 0px !important;
        `}
        styles={css`
          border-spacing: 0 5px;
        `}
        $keys={["id", "gameType", "amount", "payout", "settledAt"]}
        $setup={setup}
        rows={bets}
      />
      {bets.length === 0 ? <EmptyMessage>There is no user bets yet</EmptyMessage> : null}
    </TableWrapper>
  );
};

const EmptyMessage = styled.p`
  text-align: center;
  font-family: var(--font-family-golos), sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #d2eaff;
  margin: 20px 0 40px;
`;

export default MyBetsTabTable;
