import styled, { css, FlattenSimpleInterpolation, keyframes } from "styled-components";
import { SpinnerRingProps, FlexProps, ExtendedButton, ButtonBlueProps } from "./types";
import { ITheButton } from "components";

export interface IStyles {
  styles?: FlattenSimpleInterpolation;
}

export const $stylesReset = css`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
`;

export const $styles = css<IStyles>`
  ${({ styles }) => styles}
`;

export const NoEntitiesText = styled.div<IStyles>`
  box-sizing: border-box;

  height: 350px;

  align-items: center;
  color: #d2eaff;
  font-family: var(--font-family-golos);
  font-size: 18px;
  width: calc(90% - 10px);
  margin: auto;
  max-width: 1920px;
  font-style: italic;
  font-weight: 800;
  text-align: left;
  letter-spacing: 0.02em;
  line-height: 21px;

  display: flex;
  justify-content: center;
  margin: 20px auto;
  text-transform: uppercase;
  ${({ styles }) => styles}
`;

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Button = styled.div<IStyles & ITheButton & ExtendedButton>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: background 0.3s ease;
  &:hover {
    background: ${({ isPassive }) =>
      isPassive ? "" : "linear-gradient(102.16deg, #72b3ff 0%, #8beaff 100%)"};
  }
  &:active {
    transform: scale(0.95);
  }
  ${({ width }) =>
    typeof width === "number"
      ? css`
          width: ${width}px;
        `
      : typeof width === "string"
      ? css`
          width: ${width};
        `
      : null}
  ${({ height }) =>
    typeof height === "number"
      ? css`
          height: ${height}px;
        `
      : typeof height === "string"
      ? css`
          height: ${height};
        `
      : null}
	padding: 10px 32px;
  border-radius: 8px;
  line-height: 150%;
  font-weight: 600;
  cursor: pointer !important;
  border-radius: 8px;
  background: var(--pure-sky-100, #00b2ff);
  box-shadow: 0px 2px 0px 0px #055ba0;
  ${({ isPassive }) =>
    isPassive &&
    css`
      cursor: default;
      box-shadow: none;
      background: var(--global-bg-dark-2);
      color: #abbadb;
      border: solid 2px #272d36;
      &:hover {
        background: rgba(36, 39, 70, 1);
      }
    `}
  ${({ isDark }) =>
    isDark &&
    css`
      cursor: default;
      box-shadow: none;
      background: #181f2e;
      &:hover {
        background: rgba(36, 39, 70, 1);
      }
    `}
  ${({ isDark }) =>
    isDark &&
    css`
      cursor: default;
      box-shadow: none;
      background: #181f2e;
      &:hover {
        background: rgba(36, 39, 70, 1);
      }
    `}
  ${({ isYellow }) =>
    isYellow &&
    css`
      border-radius: 8px;
      background: #eebb2a;
      box-shadow: 0px 2px 0px 0px #995200;
      &:hover {
        background: #f2cc47;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
    `}

  ${$styles}
`;

export const ButtonBlue = styled.div<ButtonBlueProps>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(101, 101, 101, 0.05), rgba(101, 101, 101, 0)),
    #101622;
  background-clip: text;
  -webkit-background-clip: text;
  color: #131925;
  ${({ isDark }) =>
    isDark &&
    css`
      color: #8999ab;
    `}
`;

// Semicolon is missing after "margin:auto". All styles after don't work.
// That bug exists already for 9 month.
// Do not fix it without double checking.
export const DualRingLoader = styled.div<SpinnerRingProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80px;
  height: 80px;
  ${({ smallRing }) =>
    smallRing &&
    css`
      width: 15px;
      height: 15px;
    `};
  ${({ auto }) =>
    auto &&
    css`
      width: 100%;
      height: 100%;
    `};
`;

export const DualRingInner = styled.div<SpinnerRingProps>`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: ${spinAnimation} 1.2s linear infinite;
  ${({ smallRing }) =>
    smallRing &&
    css`
      width: 15px;
      height: 15px;
    `};

  ${({ auto }) =>
    auto &&
    css`
      width: 100%;
      height: 100%;
    `};
`;

const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border: 1px #232a3b solid;
  @media (max-width: 1280px) {
    width: calc(33.33% - 10px);
  }
  @media (max-width: 768px) {
    width: calc(100% - 10px);
  }

  background: #181f2e;
  border-radius: 8px;
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #77859a;
  transform: translateY(-50%);
`;

export const CenteredTriangleCard = styled(Card)`
  position: relative;
  cursor: pointer;
  height: 40px;
  width: 40px;
  margin-right: 10px !important;

  & ${Triangle} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Flex = styled.div<FlexProps>`
  display: flex;
  position: relative;
  box-sizing: border-box;
  gap: ${({ gap }) => (gap ? `${gap}px` : "0px")};
  color: ${({ color }) => color || ""};
  opacity: ${({ opacity }) => opacity || 1};
  cursor: ${({ cursor }) => cursor || "inherit"};
  box-shadow: ${({ shadow }) => shadow || "none"};
  margin: ${({ marginStr }) => marginStr || "0px"};
  padding: ${({ paddingStr }) => paddingStr || "0px"};
  align-items: ${({ align }) => align || "flex-start"};
  text-align: ${({ textAlign }) => textAlign || "start"};
  justify-content: ${({ justify }) => justify || "flex-start"};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? "wrap" : "nowrap")};
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  background: ${({ background }) => background || "transparent"};
  ${({ height }) => height && `height: ${typeof height === "number" ? `${height}px` : height}`};
  ${({ shrink }) => shrink && `flex-shrink: ${shrink}`};
  ${({ scroll }) => scroll && "overflow: auto"};
  ${({ flex }) => flex && `flex: ${flex}`};
  ${({ overflow }) => overflow && "overflow: hidden"};
  ${({ grow }) => grow && `flex-grow: ${grow}`};
  ${({ border }) => border && `border: ${border}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ borderTop }) => borderTop && `border-top: ${borderTop}`};
  ${({ width }) => width && `width: ${typeof width === "number" ? `${width}px` : width}`};
  ${({ maxWidth }) =>
    maxWidth && `max-width: ${typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth}`};
  ${({ minWidth }) =>
    minWidth && `min-width: ${typeof minWidth === "number" ? `${minWidth}px` : minWidth}`};
  ${({ minHeight }) =>
    minHeight && `min-height: ${typeof minHeight === "number" ? `${minHeight}px` : minHeight}`};
  ${({ maxHeight }) =>
    maxHeight && `max-height: ${typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight}`};
  ${({ flexBasis }) => flexBasis && `flex-basis: ${flexBasis}`};
  ${({ borderLeft }) => borderLeft && `border-left: ${borderLeft}`};
  ${({ borderColor }) => borderColor && `border-color: ${borderColor}`};
  ${({ borderRight }) => borderRight && `border-right: ${borderRight}`};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom}`};
  ${({ switchToColumn }) =>
    switchToColumn &&
    css`
      @media (max-width: 1600px) {
        flex-direction: column;
      }
    `};
  ${({ inlineCss }) =>
    inlineCss &&
    css`
      ${inlineCss}
    `};
`;

const pulseKeyframesGreen = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.4);
  }
  70% {
    box-shadow: 0 0 0 8px rgba(76, 175, 80, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
`;

// const pulseKeyframesRed = keyframes`
//   0% {
//     box-shadow: 0 0 0 0 rgba(204, 44, 44, 0.4);
//   }
//   70% {
//     box-shadow: 0 0 0 10px rgba(204, 44, 44, 0);
//   }
//   100% {
//     box-shadow: 0 0 0 0 rgba(204, 44, 44, 0);
//   }
// `;

export const PulsingIndicator = styled.div<{ live: boolean }>`
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;

  ${({ live }) =>
    live
      ? css`
          background: #5ebd3d;
          box-shadow: 0 0 0 #5ebd3d;
          animation: ${pulseKeyframesGreen} 1s infinite;
        `
      : css`
          background: rgba(200, 66, 48, 1);
          box-shadow: 0 0 0 rgba(204, 44, 44, 0.4);
        `};

  &:hover {
    animation: none;
  }
`;
