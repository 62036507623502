import React, { useEffect, useState } from "react";
import {
  DualRingInner,
  DualRingLoader,
  Flex,
  ITheTableCell,
  Pages,
  TheTable,
} from "components/common";
import Image from "next/image";
import { DateTime } from "luxon";
import styled, { css } from "styled-components";
import { BoxPropsMapper, CoinBoxHistoryItem, CoinBoxType } from "types/treasury";
import { useCoinBoxHistory } from "hooks/treasury/useCoinBoxHistory";
import CurrencyNumbers from "components/wrewards-ui-kit/CurrencyNumbers";
import CoinBoxProvably from "./CoinBoxProvably";
import { useAppContext } from "contexts";
const take = 5;
interface CoinBoxesHistoryTabProps {
  userId?: string;
}
const CoinBoxesHistoryTab: React.FC<CoinBoxesHistoryTabProps> = ({ userId }) => {
  const { profile } = useAppContext();

  const [skip, setSkip] = useState<number>(0);

  const userIdToFetch = profile?.isAdmin ? userId : undefined;

  const { data, isFetching } = useCoinBoxHistory({ userId: userIdToFetch, take, skip });

  const setup = React.useMemo(
    () => [
      {
        $key: "coinBoxId",
        render: (props: ITheTableCell<CoinBoxHistoryItem, CoinBoxHistoryItem>) => {
          return <Cell>{props.cell}</Cell>;
        },
        title: <TableHeader>id</TableHeader>,
      },
      {
        $key: "coinBox",
        render: (props: ITheTableCell<CoinBoxHistoryItem, CoinBoxHistoryItem>) => {
          return (
            <Cell>
              <Image
                src={
                  BoxPropsMapper[props.row?.coinBox.type as CoinBoxType]?.image ||
                  BoxPropsMapper[CoinBoxType.MINUTE].image
                }
                alt=""
                width={40}
                height={40}
                style={{ objectFit: "contain" }}
              />
            </Cell>
          );
        },
        title: <TableHeader>box</TableHeader>,
      },
      {
        $key: "reward",
        render: (props: ITheTableCell<CoinBoxHistoryItem, CoinBoxHistoryItem>) => {
          return (
            <Flex justify="center">
              <CurrencyNumbers icon="GCoin">{props.cell}</CurrencyNumbers>
            </Flex>
          );
        },
        title: <TableHeader>reward</TableHeader>,
      },
      {
        $key: "coinBoxFairnessHistory",
        render: (props: ITheTableCell<CoinBoxHistoryItem, CoinBoxHistoryItem>) => {
          return (
            <CoinBoxProvably
              clientSeed={props.row?.coinBoxFairnessHistory.clientSeed || ""}
              serverSeed={props.row?.coinBoxFairnessHistory.serverSeed || ""}
            />
          );
        },
        title: <TableHeader>Fairness</TableHeader>,
      },
      {
        $key: "openedAt",
        render: (props: ITheTableCell<CoinBoxHistoryItem, CoinBoxHistoryItem>) => {
          return <Cell>{DateTime.fromISO(props.cell).toFormat("dd.MM.yyyy HH:mm")}</Cell>;
        },
        title: <TableHeader>date</TableHeader>,
      },
    ],
    []
  );

  const onPage = (index: number) => {
    setSkip(index * take);
  };
  return (
    <TableWrapper>
      {isFetching ? (
        <Flex align="center" justify="center" padding="120px 0px">
          <DualRingLoader>
            <DualRingInner />
          </DualRingLoader>
        </Flex>
      ) : (
        <div style={{ overflowX: "auto" }}>
          <TheTable
            $keys={["coinBoxId", "coinBox", "reward", "coinBoxFairnessHistory", "openedAt"]}
            $setup={setup}
            rows={data?.items ?? []}
            boxStyles={css`
              margin: 0px !important;
            `}
            styles={css`
              border-spacing: 0 5px;
            `}
          />
        </div>
      )}

      <Pages
        styles={css`
          flex-wrap: wrap !important;
          margin-top: 12px !important;
        `}
        showInfo={false}
        skip={skip}
        take={take}
        total={data?.totalCount || 0}
        onClick={(index) => {
          onPage(index);
        }}
      />
    </TableWrapper>
  );
};

export const TableWrapper = styled.div`
  width: 100%;
  & > div {
    margin: 0;
  }
  & table {
    border-spacing: 0 8px;
    tbody {
      tr {
        background: #24274680;
      }
    }
  }

  @media (max-width: 600px) {
    overflow: scroll;
    td {
      padding: 4px 8px;
    }
  }
`;

const TableHeader = styled.h6`
  margin: 0;
  width: 100%;
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #e9f4ff80;
  text-transform: uppercase;
`;

const Cell = styled.div<{ withPoints?: boolean }>`
  display: flex;
  ${({ withPoints }) =>
    withPoints
      ? `
        justify-content: flex-start;
        padding-left: 24px;
        @media (max-width: 600px) {
          padding-left: 0;
        }
        `
      : `justify-content: center;`}
  align-items: center;
  gap: 4px;

  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  color: #e9f4ffbf;
`;

const EmptyMessage = styled.p`
  text-align: center;
  font-family: var(--font-family-golos), sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: #d2eaff;
  margin: 20px 0 40px;
`;

export default CoinBoxesHistoryTab;
