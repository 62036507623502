import { ButtonHTMLAttributes } from "react";
import WrewardsButton, { ButtonVariant } from "../Button";
import { DualRingInner, DualRingLoader } from "components/common";
import { SpinnerRingProps } from "components/common/types";

interface WrewardsActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  isLoading?: boolean;
  loaderProps?: SpinnerRingProps;
  isActive?: boolean;
}
const WrewardsActionButton = ({
  variant,
  children,
  isLoading = false,
  loaderProps,
  isActive,
  ...btnProps
}: WrewardsActionButtonProps & React.PropsWithChildren) => {
  return (
    <WrewardsButton data-active={isActive} variant={variant} {...btnProps}>
      {isLoading ? (
        <DualRingLoader {...(loaderProps ? loaderProps : { auto: true })}>
          <DualRingInner {...(loaderProps ? loaderProps : { auto: true })}></DualRingInner>
        </DualRingLoader>
      ) : (
        children
      )}
    </WrewardsButton>
  );
};

export default WrewardsActionButton;
